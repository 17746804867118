import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { navigate } from "gatsby-link";
import api, { url } from "../../api";
import { AuthContext } from "../../context/GlobalContextProvider";
import { Button } from "antd";
import ClassCard from "./components/classCard";

export default function NossasLinhas() {
  const [page, setPage] = useState();
  useEffect(() => {
    getPage();
  }, []);
  const context = useContext(AuthContext);
  const getPage = async () => {
    const { data } = await api.get("/nossas-linhas");
    setPage(data);
  };

  return (
    <Layout
      aboveFooter
      titlePage="Nossas Linhas"
      pageDesctiption={page?.conteudo}
      loading={!page}
    >
      <SEO
        keywords={[`Bivver`, `Empreendimentos`, `imobiliários`, `praia`, `prédios`]}
        title="Nossas Linhas"
      />
      {context?.user && (
        <Button
          style={{ borderColor: "orange", color: "orange" }}
          danger
          className="mb-5"
          onClick={() => navigate(`/edicao/nossasLinhas`)}
        >
          Editar
        </Button>
      )}
      <div className="grid lg:grid-cols-2 gap-12 mb-10 lg:mt-16">
        <ClassCard
          title="Bivver Dream"
          url="/nossasLinhas/bivverDream"
          photo={url + page?.fotoDream?.url}
          text={page?.textoDream}
        />
        <ClassCard
          title="Bivver Club"
          url="/nossasLinhas/bivverClub"
          photo={url + page?.fotoClub?.url}
          text={page?.textoClub}
        />
        <ClassCard
          title="Bivver Exclusivity"
          url="/nossasLinhas/bivverExclusivity"
          photo={url + page?.fotoExclusivity?.url}
          text={page?.textoExclusivity}
        />
      </div>
    </Layout>
  );
}
